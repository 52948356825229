<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add Role</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
          <b-form v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="roleData.name" 
                              @blur="roleData.name.$touch()"></b-form-input>              
              </b-col>  
            </b-row>
            <b-row v-if="true" class="mb-3">
              <b-col cols="12">
                <br />
                <h4
                  style="text-align: center; background: #4e5760; color: #fff; padding: 5px 30px; border-radius: 3px 3px 0 0; ">Tags</h4>
                <br />

              </b-col>
              <b-col cols="12">
              <b-form-group>
                <b-form-checkbox-group v-model="selected"  id="checkbox-group-1">
                  <b-form-checkbox v-for="(item, index) in tags" :key="index" :value="item" >{{item.name}}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

  </b-col>
            </b-row>

            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submitRole()" class="ml-2">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
          
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>
import {mapActions, mapMutations} from "vuex";
import {required} from "vuelidate/lib/validators";
import api from "../../../api";

export default {
  name: "createRole",
  data: () => ({
    state: 'show',
    roleData: {
      name: null,     
      roleTagIds: [],
    },
    tags: [],
    selected: [],
  }),
  created() {
    
    this.setBreadcrumb([
      {
        text: 'Tag'
      },
      {
        text: 'Add New Role'
      },
    ]);
    const s = (data) => {
        this.tags = data;
      };

      const e = (msg) => {
        console.log(msg);
      };
      api.getAllTags(s, e);
  },
  methods: {
    ...mapActions(["createTag"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage']),

    submitRole() {
        const s = (data) => {   
          this.$router.push ({path: '/admin/ManageRoles/Searchrole'})      
          console.log( data);
        };
        
        this.selected.forEach(item => this.roleData.roleTagIds.push(item.id));

        const e = (msg) => {
          console.log(msg);
        };
         
        api.CreateRole(this.roleData, s, e)
    },
     goBackToSearch() {
        this.$router.back()
      }, 

  },
 computed: {},
  validations: {
    roleData: {
      name: {required},
    },
  },
}
</script>

<style scoped>

</style>